<ng-container *transloco="let t">
  <app-dialog
    title="{{ t('device.move-dialog.title') }}"
    descriptiveSubtitle="{{ t('device.move-dialog.subtitle') }}"
    [steps]="steps"
    [type]="type"
    [contentTemplate]="[page1, page2]"
    [currentPage]="currentPageIndex">
  </app-dialog>

  <ng-template #page1>
    <form [formGroup]="form" class="d-flex flex-column justify-content-center mt-5 w-90 needs-validation">
      <app-select
        inputId="virtualLaboratory"
        label="device.move-dialog.virtual-laboratory-selection.key"
        class="pb-5"
        formControlName="virtualLaboratory"
        emptyOption="device.move-dialog.virtual-laboratory-selection.empty-option"
        [options]="virtualLaboratorySelectOptions()"
        [isValid]="isVirtualLaboratoryValid"
        [messageInfo]="virtualLaboratoryInfoMessages"></app-select>
    </form>
  </ng-template>

  <ng-template #page2>
    <div class="mt-10 d-flex align-items-center result-message">
      <app-svg-icon name="icon-checkmark" class="checkmark"> </app-svg-icon>
      <p class="m-0 p-0">{{ t('device-successfully-moved') }}</p>
    </div>
  </ng-template>
</ng-container>
