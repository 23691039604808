<ng-container *transloco="let t">
  <app-dialog
    title="{{ t('device.registration-dialog.title') }}"
    descriptiveSubtitle="{{ t('device.registration-dialog.subtitle') }}"
    [steps]="(isOffline.valueChanges | async) ? [steps[0], steps[3]] : steps"
    [type]="type"
    [contentTemplate]="[page1, page2, page3, page4]"
    [currentPage]="currentPageIndex">
  </app-dialog>

  <ng-template #page1>
    <form [formGroup]="form" class="d-flex flex-column justify-content-center align-items-center w-100 needs-validation">
      <div class="subheading-template d-flex justify-content-center align-items-center mb-6">
        <div class="w-90 d-flex justify-content-between">
          <div>{{ t('device.registration-dialog.offline-description') }}</div>
          <div class="ml-5">
            <app-toggle formControlName="isOffline"></app-toggle>
          </div>
        </div>
      </div>
      <app-select
        inputId="virtualLaboratory"
        label="device.registration-dialog.virtual-laboratory-selection.label"
        class="pb-5 w-90"
        formControlName="virtualLaboratory"
        emptyOption="device.registration-dialog.virtual-laboratory-selection.empty-option"
        [options]="virtualLaboratorySelectOptions"
        [isValid]="isVirtualLaboratoryValid"
        [messageInfo]="virtualLaboratoryInfoMessages"></app-select>
      <app-select
        inputId="vendor"
        label="device.registration-dialog.vendor-selection.label"
        class="pb-5 w-90"
        formControlName="vendor"
        emptyOption="device.registration-dialog.vendor-selection.empty-option"
        [options]="vendorSelectOptions"
        [isValid]="isVendorValid"
        [messageInfo]="vendorInfoMessages"></app-select>
      <app-select
        inputId="model"
        label="device.registration-dialog.model-selection.label"
        class="pb-5 w-90"
        formControlName="model"
        emptyOption="device.registration-dialog.model-selection.empty-option"
        [loading]="isModelsLoading | async"
        [isValid]="isModelValid"
        [messageInfo]="modelInfoMessages"
        [hidden]="!isOffline.value"
        [options]="modelSelectOptions"></app-select>
      <app-input
        inputId="serialNumber"
        label="device.registration-dialog.serial-number-input.label"
        class="pb-5 w-90"
        formControlName="serialNumber"
        placeholder="{{ t('device.registration-dialog.serial-number-input.placeholder-text') }}"
        [validateOnKeyPress]="true"
        [isValid]="isSerialNumberValid"
        [messageInfo]="serialNumberInfoMessages"></app-input>
      @if (offlineRegistrationError) {
        <p class="m-0 p-0 mt-6 w-90 text-danger">{{ t(offlineRegistrationError) }}</p>
      }
    </form>
  </ng-template>

  <ng-template #page2>
    <div class="mt-10 d-flex align-items-center">
      <p class="m-0 p-0 pl-3">{{ t('device.registration-dialog.running-correct-software') }}:</p>
      <p class="m-0 p-0 pl-2 pr-1 software-version">{{ softwareVersion() }}</p>
    </div>
    <p class="instruction-text pr-1 pl-5 mt-2 mb-10 w-90">
      {{ t('device.registration-dialog.download-software-update') }}
    </p>

    @if (!isLoading) {
      <div class="w-90">
        @for (software of softwareOptions(); track software; let i = $index) {
          <span class="d-flex">
            <p class="software-name pl-2">{{ software.name }}</p>
            <app-button
              buttonClass="btn-primary-light ml-1"
              [icon]="downloadIcon"
              title=""
              [handler]="softwareDownloadAction[i].handler ? softwareDownloadAction[i].handler : noopHandler"
              [disabled]="softwareDownloadAction[i].isEnabled && !softwareDownloadAction[i].isEnabled()"
              [isWorkInProgress]="softwareDownloadAction[i].isWorking && softwareDownloadAction[i].isWorking()">
            </app-button>
          </span>
        }
      </div>
    } @else {
      <app-loading-indicator></app-loading-indicator>
    }
  </ng-template>

  <ng-template #page3>
    <div class="mt-10 token-container">
      <p class="token-display d-flex align-items-center" data-cy="token">
        {{ token }}
        <span class="spinner-text d-flex align-items-center pl-3">
          <div class="pr-1">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
          {{ t('device.registration-dialog.waiting-for-registration') }}
        </span>
      </p>
      <p class="instruction-text">
        {{ t('device.registration-dialog.token-usage-instructions') }}
      </p>
    </div>
  </ng-template>

  <ng-template #page4>
    <div class="mt-10 d-flex align-items-center result-message">
      <app-svg-icon name="icon-checkmark" class="checkmark"> </app-svg-icon>
      @if (true) {
        <p class="m-0 p-0">{{ t('device.registration-dialog.result-message.success') }}</p>
      } @else {
        <p>{{ t('device.registration-dialog.result-message.error') }}</p>
      }
    </div>
    <ng-template #error>
      <p>{{ t('device.registration-dialog.result-message.error') }}</p>
    </ng-template>
  </ng-template>

</ng-container>
